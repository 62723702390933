/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


                // JavaScript to be fired on all pages

                    function mover(element,url) {
                        
                        element.css('background', url  );

                    }

                 
                 jQuery('.icon-slider').slick({
                  
                    // infinite: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    prevArrow: '.white-prev',
                    nextArrow: '.white-next',

                         responsive: [
                    
                        {

                            breakpoint: 1024,
                    
                            settings: {

                                slidesToShow: 3,
                                slidesToScroll: 3,
                                infinite: true,

                            }

                        },

                        {

                            breakpoint: 600,

                            settings: {

                                slidesToShow: 2,
                                slidesToScroll: 2,
                                

                            }

                        },

                        {
                            breakpoint: 480,

                            settings: {
                                
                                slidesToShow: 1,
                                slidesToScroll: 1
                    
                            }

                        }
                
                    ]
                            
                });


                jQuery('.img-slider').slick({
                  
                    // infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    prevArrow: '.black-prev',
                    nextArrow: '.black-next',

                         responsive: [
                    
                        {

                            breakpoint: 1024,
                    
                            settings: {

                                slidesToShow: 3,
                                slidesToScroll: 3,
                                infinite: true,

                            }

                        },

                        {

                            breakpoint: 600,

                            settings: {

                                slidesToShow: 2,
                                slidesToScroll: 2,
                                

                            }

                        },

                        {
                            breakpoint: 480,

                            settings: {
                                
                                slidesToShow: 1,
                                slidesToScroll: 1
                    
                            }

                        }
                
                    ]
                            
                });


                jQuery('.testimonial-slider').slick({
                  
                    // infinite: true,
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    slidesToScroll: 1,
                    arrows: true

                         
                });

                
                
                var scroll = 0;
                
                // Header Scroll

                 if (jQuery(window).width() > 768) {

                jQuery( window ).scroll(function() {
                    
                    var scrollPos = $(window).scrollTop();
                    
                    if ( scrollPos > 100 ) {
                        
                        if ( scroll === 0 ) {
                            
                            jQuery('.home header').addClass('header-2');

                            jQuery('header').addClass('white-head');
                            
                            scroll++;
                            
                        }
                        
                    }
                    
                    if ( scrollPos === 0 ) {
                        
                        jQuery('.home header').removeClass('header-2');

                        jQuery('header').removeClass('white-head');

                        scroll = 0;
                        
                    }

                });

            } 


              if (jQuery(window).width() > 768) {

                jQuery('.contact-btn').addClass('btn-blue');

              } else {

                jQuery('.contact-btn').removeClass('btn-blue');

              }

                
                // Responsive Menu
                
                jQuery('.menu-primary').slicknav({
                    
                    label:          '',
                    prependTo:      'header .nav-primary',	
                    
                });

            var page = 2;

            jQuery(".test-ajax").click(function(e){

                jQuery(".test-ajax").css("display", "none"); 

                jQuery(".load-icon").fadeIn();//.css("display", "inline-block");

            var cat_id = jQuery(".case-menu .current-post a").attr("data-cat");   

            e.preventDefault();

                    jQuery.ajax({  
                    type: 'POST',  
                    url: ajaxurl,  
                    data: {  

                        action: 'loadmore_ajax_response', 
                        page: page,
                        category : cat_id

                    }, 
                   

                    success: function(data){  

                        if (data) {
    
                            jQuery( data.toString() ).appendTo(".loadrow").fadeIn();

                            jQuery(".test-ajax").css("display", "inline-block"); 

                            jQuery(".load-icon").css("display", "none");

                        } else {

                            jQuery(".no-more").css("display", "inline-block");

                        }


                    },  
                    error: function(MLHttpRequest, textStatus, errorThrown){  
                        //alert(errorThrown);  
                    }  
                });  
                
                page++;

            });

             jQuery('#horizontalTab').easyResponsiveTabs({
	
                    type: 'default', //Types: default, vertical, accordion           
                    width: 'auto', //auto or any width like 600px
                    fit: true,   // 100% fit in a container
                    closed: 'accordion', // Start closed if in accordion view
                    activate: function(event) { // Callback function if tab is switched
                    var $tab = jQuery(this);
                    var $info = jQuery('#tabInfo');
                    var $name = jQuery  ('span', $info);
                    $name.text($tab.text());
                    $info.show();
                    }

                });

                jQuery('#verticalTab').easyResponsiveTabs({
                type: 'vertical',
                width: 'auto',
                fit: true
            });
            
                jQuery(".resource-btn").click(function(e) {

                    e.preventDefault();

                    jQuery('#download-form .postid').val(jQuery(this).attr("data-id"));
                    jQuery('#download-form .postname').val(jQuery(this).attr("data-title"));
                    jQuery('#download-form .resourceurl').val(jQuery(this).attr("data-url"));

                    jQuery('.resource-form').bPopup({

                        position: ['auto', 'auto'],

                        onOpen: function() {

                            jQuery('#download-form').trigger("reset");

                            var validator = jQuery("#download-form").validate();
                            validator.resetForm();

                        },

                    });

                });

                    jQuery('.show-pop').bind('click', function(e) {

                    //Prevents the default action to be triggered.
                    e.preventDefault();

                    var apply = jQuery('.post-featuredimg h1').text();
                
                    var query = 'span.frompost input';
                    debugger;

                    var target = jQuery(this).attr("href");
                    // Triggering bPopup when click event is fired
                    jQuery(target).bPopup({

                        positionStyle: 'fixed',

                        onOpen: function() {

                            jQuery(query).val(apply);

                        } 

                    });
                    

                });

      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
       
          jQuery('.slider').slick({
                  
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1
                      
          });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
